import { makeStyles } from 'tss-react/mui'
import Bg from 'src/assets/images/home/bgSlider.png'
import BlobBg from 'src/assets/images/home/blobBg.jpg'
import { nunito_sans } from '@styles/fonts'
import { lato } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  sliderWrapper: {
    backgroundImage: `url(${Bg?.src})`,
    backgroundSize: '600px',
    backgroundPosition: '0 30px',
    backgroundColor: '#F0F0F0',
    borderRadius: '60px',
    backgroundRepeat: 'no-repeat',
    padding: theme.spacing(10, 0, 30),
    position: 'relative',
    zIndex: '22',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(10, 0, 1),
      paddingBottom: theme.spacing(0),
      borderRadius: '25px',
    },
  },
  configBox: {
    height: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  configInnerBox: {
    maxWidth: theme.maxWidth,
    padding: theme.spacing(0, 20),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  blobContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${BlobBg?.src})`,
    backgroundSize: '100% 100%',
    position: 'relative',
    top: '-5rem',
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'none',
      position: 'static',
    },
  },
  getRecommend: {
    position: 'relative',
    top: '-13.5rem',
    zIndex: '222',
    margin: theme.spacing(30, 0, 35),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(15, 0, 24),
      position: 'static',
    },
  },
  imgWrapper: {
    position: 'relative',
    top: '-12rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  content: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },

  title: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '64px',
    textTransform: 'uppercase',
    lineHeight: '100%',
    textAlign: 'center',
    color: '#FFFFFF',
    paddingBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      // width: '70%',
    },
  },
  text: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center',
    marginBottom: theme.spacing(6.2),

    [theme.breakpoints.down('md')]: {
      // width: '70%',
    },
  },
  btn: {
    // width: '100%',
    margin: 'auto',
    background: 'black',
    color: 'white',
    textAlign: 'center',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },

  inspiredWrapper: {
    background: '#F2F2F2',
    margin: 'auto',
    padding: theme.spacing(20, 0, 90),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(15, 0, 75),
    },
  },
  inspiredTitle: {
    width: '70%',
    margin: 'auto',

    textAlign: 'center',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 800,
    fontSize: '72px',
    textTransform: 'uppercase',
    lineHeight: '84px',
    color: '#000000',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '34px',
      lineHeight: '36px',
    },
  },
  tagsWrapper: {
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    justifyContent: 'start',
    margin: 'auto',
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),

    [theme.breakpoints.down('md')]: {},
    '&:hover>*': {
      animationPlayState: 'paused',
    },
  },
  innerTag: {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    animation: 'marquee 15s linear infinite',

    '@keyframes marquee': {
      '0%': {
        transform: 'translateX(0%)',
      },
      '100%': {
        transform: 'translateX(-100%)',
      },
    },
  },

  topPostsWrapper: {
    marginTop: theme.spacing(-70),
  },
  videoSliderWrapper: {
    position: 'relative',

    marginTop: theme.spacing(-50),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(-0),
    },
  },

  zenModeWithBg: {
    backgroundImage: `none`,
    backgroundColor: 'transparent',
  },
  zenMode: {
    backgroundImage: `none`,
    backgroundColor: 'transparent',
  },
  zenImg: {
    filter: 'grayscale(100%)',
    position: 'relative',
    zIndex: '-1',
  },
  zenRootContainer: {
    width: '100%',
    maxWidth: '926px',
    margin: '50px auto',
    marginTop: '200px',
    position: 'relative',
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  crossIcon: {
    width: '16px',
    height: '16px',
    color: 'white',
    position: 'absolute',
    right: '100px',
    top: '30px',
    cursor: 'pointer',
  },
  zenContentContainer: {
    padding: '40px 0px',
    background: '#000000',
    border: '1px solid #000000',
    borderRadius: '32px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imgContainer: {
    position: 'relative',
    width: '53px',
    height: '36px',
    marginBottom: '12px',
  },
  zenCardHeading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '34px',
    lineHeight: '46.38px',
    textTransform: 'uppercase',
    color: 'white',
    textAlign: 'center',
    marginBottom: '6px',
  },
  zenCardDescription: {
    maxWidth: '626px',
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '25px',
    textAlign: 'center',
    color: '#FFFFFF',
    opacity: '0.8',
  },

  btnWrapper: {
    width: 'fit-content !important',
    margin: 'auto',
    marginTop: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
}))

export default useStyles
