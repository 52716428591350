import { Typography, Box } from '@mui/material'
import React from 'react'
import useStyles from './Style'
import Tag from 'src/components/shared/atoms/Tag/Tag'
import { InspiredYetI } from 'src/types/store/index'
import { useRouter } from 'next/router'

interface Props {
  zenMode: boolean
  inspiredYet: InspiredYetI
}
function Inspired({ zenMode, inspiredYet }: Props) {
  const { classes } = useStyles()
  const router = useRouter()
  return (
    <div
      className={`${classes.inspiredWrapper} ${
        zenMode && classes.zenModeWithBg
      } `}
    >
      <Typography className={classes.inspiredTitle}>
        {inspiredYet.heading}{' '}
      </Typography>
      <Box className={classes.tagsWrapper}>
        <div className={classes.innerTag}>
          {[...inspiredYet?.tags, ...inspiredYet?.tags]?.map((tag, idx) => {
            return (
              <Tag
                key={idx}
                id={idx}
                background="white"
                content={tag.tagName}
                style={{
                  border: 'none',
                  fontWeight: 700,
                  padding:
                    typeof window === 'object' && window.innerWidth > 600
                      ? '2rem 2rem'
                      : '1rem 1rem',
                  fontSize:
                    typeof window === 'object' && window.innerWidth > 600
                      ? '36px'
                      : '14px',
                  minWidth: 'fit-content',
                }}
                onClick={() => {
                  router.push(tag.link)
                }}
              />
            )
          })}
        </div>
        <div className={classes.innerTag}>
          {[...inspiredYet?.tags, ...inspiredYet?.tags]?.map((tag, idx) => {
            return (
              <Tag
                key={idx}
                id={idx}
                background="white"
                content={tag.tagName}
                style={{
                  border: 'none',
                  padding:
                    typeof window === 'object' && window.innerWidth > 600
                      ? '2rem 2rem'
                      : '1rem 1rem',
                  fontSize:
                    typeof window === 'object' && window.innerWidth > 600
                      ? '36px'
                      : '14px',
                  minWidth: 'fit-content',
                }}
                onClick={() => {
                  router.push(tag.link)
                }}
              />
            )
          })}
        </div>
      </Box>
    </div>
  )
}

export default Inspired
