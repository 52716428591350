import React from 'react'
import { Grid, Typography } from '@mui/material'
import useStyles from './Style'
import { ButtonComponent } from '../../atoms'
import robot from 'src/assets/svgs/robot.svg'
import Image from 'next/image'
import { useRouter } from 'next/router'
import mainImage from 'src/assets/images/getRecommend/getRecommend.jpg'

interface btnData {
  id: number
  content: string
  href: string
}
interface Props {
  content: {
    heading: string
    para: string
    btnDetail: btnData[]
  }
  zenMode: boolean
}

const GetRecommend: React.FC<Props> = ({ zenMode, content }) => {
  const [authenticated, setAuthenticated] = React.useState(false)
  const { classes } = useStyles()
  const { heading, para, btnDetail } = content
  const router = useRouter()

  React.useEffect(() => {
    const userToken: string | null =
      typeof window === 'object' ? localStorage.getItem('dtl_token') : null
    if (userToken) {
      if (JSON.parse(userToken).id) setAuthenticated(true)
    }
  }, [])

  return (
    <Grid className={classes.rootContainer}>
      <Grid
        container
        className={
          !zenMode
            ? classes.getRecommendContainer
            : ` ${classes.getRecommendContainer} ${classes.getRecommendContainerZenMode} `
        }
        sx={{ position: 'relative', overflow: 'hidden' }}
      >
        {!zenMode && (
          <div
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              zIndex: -1,
              width: '100%',
              height: '100%',
            }}
          >
            <Image src={mainImage?.src} alt={'background'} fill />
          </div>
        )}

        <Grid className={classes.getRecommandLeftContainer} item md={8} sm={12}>
          <Typography className={classes.getRecommendHeading} variant="h3">
            {heading}
          </Typography>
          <Typography className={classes.primaryText}>{para}</Typography>
          <Grid className={classes.btnContainer} container spacing={4}>
            <Grid item className={classes.recommendBtnContainer}>
              {!authenticated ? (
                <ButtonComponent
                  type="contained"
                  gradient
                  text={btnDetail[0]?.content}
                  className={classes.recommendBtn}
                  onClick={() => {
                    router.push('/auth/signup')
                  }}
                />
              ) : null}
            </Grid>
            <Grid item className={classes.hideOnSm}>
              <ButtonComponent
                type="outlined"
                text={btnDetail[1]?.content}
                onClick={() => {
                  router.push(btnDetail[1].href ? btnDetail[1].href : '#')
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={classes.getRecommandRightContainer}
          item
          md={4}
          sm={12}
          container
        >
          {!zenMode && (
            <Image
              className={classes.robotImage}
              src={robot?.src}
              alt="robot"
              width="218"
              height="251"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default GetRecommend
