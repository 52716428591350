import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
import { lato } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  rootWrapper: {},

  outerBox: {
    height: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  InnerBox: {
    maxWidth: theme.maxWidth,
    width: '100%',
    padding: theme.spacing(0, 20),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
      marginBottom: theme.spacing(12),
    },
  },
  cardWrapper: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '60px',
    background: 'white',
    [theme.breakpoints.down('md')]: {
      borderRadius: '40px',
    },
  },
  imgWrapper: {
    width: '100%',
    height: '100%',
    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '300px',
      img: {
        width: '100%',
        height: '250px',
        borderRadius: '20px',
        objectFit: 'cover',
      },
    },
  },

  title: {
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 700,
    fontSize: '64px',
    textTransform: 'uppercase',
    lineHeight: '87px',
    color: '#000000',
    textAlign: 'start',
    '@media (max-width:1150px)': {
      fontSize: '34px',
      lineHeight: '46px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  desc: {
    fontFamily: lato.style.fontFamily,
    fontWeight: 400,
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'start',
    opacity: '.7',
    textOverflow: 'ellipsis',
    '@media (max-width:1150px)': {
      fontSize: '16px',
      lineHeight: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
      height: '100px',
      textOverflow: 'ellipsis',
      overflow: 'scroll',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: '1rem',
      height: '80px',
      textOverflow: 'ellipsis',
      overflow: 'scroll',
    },
  },
  tagsWrapper: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    margin: theme.spacing(5, 0),
    textAlign: 'start',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  learnMoreBtn: {
    background: 'black',
    color: 'white',
    textAlign: 'center',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },
  btnWrapper: {
    width: 'fit-content !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },

  zenMode: {
    border: '1px solid black',
  },
  zenModeImg: {
    opacity: 0,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  card: {
    position: 'sticky',
    top: '200px',
    [theme.breakpoints.down('sm')]: {
      top: '130px',
    },
  },
}))

export default useStyles
