import { Typography } from '@mui/material'
import React from 'react'
import useStyles from './Styles'
import Emoji from 'src/assets/svgs/campaigns/emojiHug.svg'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import Image from 'next/image'
import { ButtonComponent } from 'src/components/shared/atoms'
import { getRecommend } from 'src/types/store'
import { useRouter } from 'next/router'
import Img from 'src/assets/images/helplines/confiSupport.jpg'
interface Props {
  zenMode: boolean
  confidentialSupport: getRecommend
}
function ConfidentialSupport(props: Props) {
  const { zenMode, confidentialSupport } = props
  const router = useRouter()

  const { classes } = useStyles()

  return (
    <Box
      className={`${classes.container} ${
        zenMode ? classes.containerZenMode : ''
      }`}
      mb={0}
      sx={{ position: 'relative', overflow: 'hidden' }}
    >
      {!zenMode && (
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        >
          <Image src={Img?.src} alt="background" fill />
        </div>
      )}

      {!zenMode && <Image src={Emoji} alt="image" />}
      <Typography
        className={`${classes.heading} ${zenMode ? classes.zenText : ''}`}
      >
        {confidentialSupport.heading}
      </Typography>
      <Typography
        className={`${classes.text} ${zenMode ? classes.zenText : ''}`}
      >
        {confidentialSupport.text}
      </Typography>

      <Grid
        container
        px={{ xs: 3, sm: 4, md: 20, lg: 40 }}
        justifyContent="center"
        pt={6}
        sx={{
          display: { lg: 'flex', md: 'flex', sm: 'none', xs: 'none' },
        }}
      >
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Box className={classes.cardContent}>
            <ButtonComponent
              type="contained"
              text={confidentialSupport.button[0].content}
              className={classes.getSupport}
              gradient
              onClick={() => {
                router.push(confidentialSupport.button[0].href)
              }}
            />
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={6}>
          <Box className={classes.cardContent}>
            <ButtonComponent
              type="contained"
              text={confidentialSupport.button[1].content}
              className={classes.learnMore}
              onClick={() => {
                router.push(confidentialSupport.button[1].href)
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Box
        className={classes.cardContent}
        sx={{
          display: { lg: 'none', md: 'none', sm: 'flex', xs: 'flex' },
        }}
        mt={6}
      >
        <ButtonComponent
          type="contained"
          text={confidentialSupport.button[0].content}
          className={classes.getSupport}
          gradient
          onClick={() => {
            router.push(
              confidentialSupport.button[0]
                ? confidentialSupport.button[0]?.href
                : '#'
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default ConfidentialSupport
