import React from 'react'
import MentalBg from 'src/assets/images/hub/hubBanners/mentalBg.jpg'
import BullyingBg from 'src/assets/images/hub/hubBanners/bullyingBg.jpg'
import GamingBg from 'src/assets/images/hub/hubBanners/gamingBg.jpg'
import RelationBg from 'src/assets/images/hub/hubBanners/relationBg.jpg'
import SexualityBg from 'src/assets/images/hub/hubBanners/sexualityBg.jpg'
import Card from './Card'
import useStyles from './Style'
import { healthCardsHomeI } from 'src/types/store/index'

interface Props {
  zenMode: boolean
  healthCardsHome: healthCardsHomeI[]
}
function HubCards({ zenMode, healthCardsHome }: Props) {
  const { classes } = useStyles()
  const bgArray = [MentalBg, SexualityBg, BullyingBg, RelationBg, GamingBg]
  const dta = healthCardsHome.map((card, idx) => {
    return {
      id: idx,
      title: card.heading,
      desc: card.description,
      tags: card.tags,
      img: card.media,
      imgBg: bgArray[idx],
      button: card.button,
    }
  })

  return (
    <div>
      {dta.map((card, idx) => {
        return (
          <div className={classes.card} key={idx}>
            <Card
              id={card.id}
              title={card.title}
              desc={card.desc}
              tags={card.tags}
              img={card.img}
              imgBg={card.imgBg}
              zenMode={zenMode}
              button={card.button}
            />
          </div>
        )
      })}
    </div>
  )
}

export default HubCards
