import React from 'react'
import useStyles from './Style'
import Blob from 'src/assets/images/home/blob.png'
import Image from 'next/image'
import { Typography } from '@mui/material'
import { ButtonComponent } from 'src/components/shared/atoms'
import { getRecommend } from 'src/types/store'
import { quickQuizHomeI } from 'src/types/store/index'
import { useRouter } from 'next/router'

import GetRecommend from 'src/components/shared/modules/getRecommend/GetRecommend'
interface Props {
  zenMode: boolean
  getRecommend: getRecommend
  quickQuizHome: quickQuizHomeI
}
function Quiz({ zenMode, getRecommend, quickQuizHome }: Props) {
  const { classes } = useStyles()
  const router = useRouter()

  return (
    <div
      className={`${classes.blobContainer} ${
        zenMode && classes.zenModeWithBg
      } `}
    >
      <div className={classes.getRecommend}>
        <GetRecommend
          content={{
            heading: getRecommend.heading,
            para: getRecommend.text,
            btnDetail: [...getRecommend.button],
          }}
          zenMode={zenMode}
        />
      </div>
      <div className={classes.imgWrapper}>
        <div className={classes.content}>
          <Typography className={classes.title}>
            {quickQuizHome?.heading}
          </Typography>
          <Typography className={classes.text}>
            {quickQuizHome?.description}
          </Typography>
          <ButtonComponent
            type="contained"
            text={quickQuizHome?.button.content}
            className={classes.btn}
            gradient
            wrapperClass={classes.btnWrapper}
            onClick={() => {
              router.push('/quizzes')
            }}
          />
        </div>

        <Image
          src={Blob}
          alt="blog image"
          className={` ${zenMode && classes.zenImg} `}
        />
      </div>
    </div>
  )
}

export default Quiz
