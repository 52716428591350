import { pxToRem } from '@styles/function'
import { makeStyles } from 'tss-react/mui'
// import mainImage from 'src/assets/images/getRecommend/getRecommend.jpg'
import { nunito_sans } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  rootContainer: {
    width: '100%',
    padding: theme.spacing(0, 17.5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  getRecommendContainer: {
    maxWidth: '1140px',
    margin: '0 auto',
    padding: `${pxToRem(49)} ${pxToRem(65)}`,
    // backgroundImage: `url(${mainImage?.src})`,
    borderRadius: '40px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 4),
    },
  },
  getRecommendContainerZenMode: {
    backgroundImage: `none`,
    border: '1px solid black',
  },
  getRecommandLeftContainer: {
    [theme.breakpoints.down('md')]: {
      order: 2,
      textAlign: 'center',
    },
  },
  getRecommendHeading: {
    textAlign: 'start',
    fontFamily: nunito_sans.style.fontFamily,
    fontWeight: 800,
    fontSize: pxToRem(48),
    textTransform: 'uppercase',
    lineHeight: pxToRem(63),
    marginBottom: pxToRem(10),

    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(24),
      textAlign: 'center',
      lineHeight: pxToRem(32.74),
    },
  },
  primaryText: {
    ...theme.textPrimary,
    textAlign: 'start',
    marginBottom: pxToRem(20),
    [theme.breakpoints.down('sm')]: {
      fontSize: pxToRem(14),
      textAlign: 'center',
      lineHeight: pxToRem(16.8),
      marginBottom: pxToRem(10),
    },
  },

  getRecommandRightContainer: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      order: 1,
    },
  },
  hideOnSm: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  btnContainer: {
    marginTop: '25px',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      marginBottom: '20px',
    },
  },
  recommendBtnContainer: {
    [theme.breakpoints.down('sm')]: {
      flex: 1,
    },
  },
  recommendBtn: {
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },

  learnMore: {
    background: 'transparent',
    color: 'black',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
  },
  robotImage: {
    transform: 'rotate(7.18deg)',
    [theme.breakpoints.down('md')]: {
      width: '150px',
      height: '150px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100px',
      height: '100px',
      marginTop: '20px',
      marginBottom: '20px',
    },
  },
}))

export default useStyles
