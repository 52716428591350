import { makeStyles } from 'tss-react/mui'
// import Img from 'src/assets/images/helplines/confiSupport.jpg'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  container: {
    margin: theme.spacing(12, 0, 32),
    padding: theme.spacing(12, 50),
    height: 'auto',
    // backgroundImage: `url(${Img?.src})`,
    backgroundRepeat: 'no-repeat',
    borderRadius: '50px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    '@media (max-width:1200px)': {
      padding: theme.spacing(12, 40),
      margin: theme.spacing(8, 0, 26),
    },
    '@media (max-width:1080px)': {
      padding: theme.spacing(12, 10),
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 15),
      borderRadius: '24px',
      padding: theme.spacing(6, 6, 4),
    },
  },
  containerZenMode: {
    border: '1px solid black',
    backgroundImage: 'none',
  },
  zenText: {
    color: '#000000',
  },
  heading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: ' normal',
    fontWeight: '800',
    fontSize: '64px',
    lineHeight: '72px',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '32px',
      lineHeight: 'normal',
      textAlign: 'center',
    },
  },

  viewOnSm: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',

      img: {
        paddingLeft: '4px',
        width: '45px',
        height: '44px',
      },
    },
  },
  text: {
    ...theme.textPrimary,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    opacity: '0.7',
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
      lineHeight: '20px',
      fontSize: '16px',
    },
  },

  cardContent: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0),
    },
  },
  getSupport: {
    width: '100%',
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
      fontSize: '14px',
    },
  },
  learnMore: {
    width: '100%',
    background: 'transparent',
    color: 'black',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
      fontSize: '14px',
    },
  },
}))

export default useStyles
