/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react'
import Skeleton from 'src/components/shared/modules/skeleton'
import { useSelector } from 'react-redux'
import { RootState } from 'src/store/store'
import useStyles from 'src/components/pages/home/Style'
import BrandsCollab from 'src/components/shared/modules/brandsCollab'
import TopPosts from 'src/components/shared/modules/topPosts'
// import ArticleSlider from 'src/components/shared/modules/articleSlider/ArticleSlider'
import ConfidentialSupportV2 from 'src/components/shared/modules/confidentialSupportV2'
import Quiz from 'src/components/pages/home/Quiz'
// import MeetNewFriend from 'src/components/shared/modules/meetNewFriends/MeetNewFriends'
import { Box } from '@mui/system'
// import VideoSlider from 'src/components/shared/modules/videoSlider/VideoSlider'
import Inspired from 'src/components/pages/home/Inspired'
import MentalHealth from 'src/components/pages/home/mentalHealth/HubCards'
import dynamic from 'next/dynamic'
import HeadTag from 'src/components/layout/head'

import {
  getRecommend,
  HeadingTextButton,
  NeedASafeSpaceI,
  ZenModeCardI,
} from 'src/types/store'
import HeroHeader from 'src/components/shared/modules/heroHeader/HeroHeader'
import { contentType } from 'src/types/components/shared/atom'
import {
  healthCardsHomeI,
  quickQuizHomeI,
  InspiredYetI,
} from 'src/types/store/index'
import { useInView } from 'react-intersection-observer'

const ZenModeCard = dynamic(
  () => import('src/components/pages/home/ZenModeCard'),
  {
    ssr: false,
  }
)
const MeetNewFriend = dynamic(
  () => import('src/components/shared/modules/meetNewFriends/MeetNewFriends'),
  {
    ssr: false,
  }
)
const ArticleSlider = dynamic(
  () => import('src/components/shared/modules/articleSlider/ArticleSlider'),
  {
    ssr: false,
  }
)
const VideoSlider = dynamic(
  () => import('src/components/shared/modules/videoSlider/VideoSlider'),
  {
    ssr: false,
  }
)

// import { useGetPageByNameQuery } from 'src/store/ApiCall/pagesAPI'
// import { getCookie } from 'cookies-next'

interface btnData {
  id: number
  content: string
  href: string
}
interface meetNewFriendI {
  heading: string
  text: string
  button: btnData
}

interface IProps {
  data?: any
  isLoading?: boolean
}
export default function HomePage({ data, isLoading }: IProps) {
  const zenMode = useSelector((state: RootState) => state.zenModeState.zenMode)
  const { classes } = useStyles()

  // const { data, isLoading } = useGetPageByNameQuery('homepage') // pages data api

  const [header, setHeader] = React.useState<contentType>()
  const [healthCardsHome, setHealthCardsHome] =
    React.useState<healthCardsHomeI[]>()
  const [confidentialSupport, setConfidentialSupport] =
    React.useState<getRecommend>()
  const [getRecommend, setGetRecommend] = React.useState<getRecommend>()
  const [meetNewFriends, setMeetNewFriends] = React.useState<meetNewFriendI>()
  const [quickQuizHome, setQuickQuizHome] = React.useState<quickQuizHomeI>()
  const [inspiredYet, setInspiredYetHome] = React.useState<InspiredYetI>()

  const [zenCardContent, setZenCardContent] = React.useState<ZenModeCardI>()
  const [campaignHeading, setCampaignHeading] =
    React.useState<HeadingTextButton>()
  const [popularHeading, setPopularHeading] =
    React.useState<HeadingTextButton>()
  const [trendingnHeading, setTrendingHeading] =
    React.useState<HeadingTextButton>()

  const [topPostContent, setTopPostContent] = React.useState<NeedASafeSpaceI>()

  const [placeholderText, setPlaceholderText] = React.useState<string>()
  const [poweredByAiText, setPoweredByAiText] = React.useState<string>()
  const [videoSliderRendered, setVideoSliderRendered] = React.useState(false)

  const { ref: videoSliderRef, inView } = useInView()

  React.useEffect(() => {
    if (data && data.data.length > 0) {
      const content = data.data[0].attributes
      const {
        heroHeader,
        healthCardsHome,
        confidentialSupport,
        getRecommend,
        meetNewFriend,
        quickQuizHome,
        inspiredYetHome,
        homeZenCard,
        trendingVideosList,
        campaignVideoList,
        popularArticleListHeading,
        needASafeSpace,
      } = content
      if (heroHeader) {
        const {
          primaryText,
          secondaryText,
          tertiaryText,
          topics,
          searchPlaceholder,
          poweredbyaiText,
        } = heroHeader
        const headerText: contentType = {
          content: {
            secondary: {
              content: secondaryText,
              variant: 'h3',
              type: 'secondary',
            },
            primary: {
              content: primaryText,
              variant: 'h1',
              type: 'primary',
            },
            tertiary: {
              content: tertiaryText,
              variant: 'subtitle1',
              type: 'tertiary',
            },
          },
          topics: topics.data.map(
            (curr: { id: number; attributes: { topic: string } }) => {
              return {
                id: curr.id,
                content: curr.attributes.topic,
                href: curr.attributes.topic ? curr.attributes.topic : '#',
              }
            }
          ),
        }

        setHeader(headerText)
        if (searchPlaceholder) setPlaceholderText(searchPlaceholder)
        if (poweredbyaiText) setPoweredByAiText(poweredbyaiText)
      }
      if (campaignVideoList) setCampaignHeading(campaignVideoList)
      if (trendingVideosList) setTrendingHeading(trendingVideosList)
      if (popularArticleListHeading)
        setPopularHeading(popularArticleListHeading)
      if (healthCardsHome) setHealthCardsHome(healthCardsHome)
      if (confidentialSupport) setConfidentialSupport(confidentialSupport)
      if (getRecommend) setGetRecommend(getRecommend)
      if (meetNewFriend) setMeetNewFriends(meetNewFriend)
      if (quickQuizHome) setQuickQuizHome(quickQuizHome)
      if (inspiredYetHome) setInspiredYetHome(inspiredYetHome)
      if (homeZenCard) setZenCardContent(homeZenCard)
      if (needASafeSpace) setTopPostContent(needASafeSpace)
    }
  }, [data])

  React.useEffect(() => {
    if (inView && !videoSliderRendered) {
      setVideoSliderRendered(true)
    }
  }, [inView])
  return (
    <>
      <HeadTag
        title={data?.data[0].attributes.metaTitle}
        description={data?.data[0].attributes.metaDescription}
      />

      <Box component="div">
        {zenCardContent && zenMode ? (
          <ZenModeCard zenCardContent={zenCardContent} />
        ) : (
          ''
        )}

        {header ? (
          <HeroHeader
            searchField={true}
            endAdornment={true}
            placeHolder={placeholderText}
            poweredText={poweredByAiText}
            heroContent={header}
            homeIcon={true}
            showBrp={true}
            bgPath={!zenMode ? '/Hero.mp4' : undefined}
            pageName="home"
          />
        ) : (
          <Skeleton />
        )}

        {healthCardsHome && (
          <MentalHealth zenMode={zenMode} healthCardsHome={healthCardsHome} />
        )}
        <div
          className={`${classes.sliderWrapper} ${zenMode && classes.zenMode} `}
        >
          <div className={classes.configBox}>
            <div className={classes.configInnerBox}>
              {confidentialSupport && (
                <ConfidentialSupportV2
                  zenMode={zenMode}
                  confidentialSupport={confidentialSupport}
                />
              )}
            </div>
          </div>
          {!isLoading && popularHeading && (
            <ArticleSlider headerContent={popularHeading} zenMode={zenMode} />
          )}
        </div>

        {getRecommend && quickQuizHome && (
          <Quiz
            zenMode={zenMode}
            getRecommend={getRecommend}
            quickQuizHome={quickQuizHome}
          />
        )}
        <div ref={videoSliderRef}>
          {trendingnHeading && videoSliderRendered && (
            <div className={classes.videoSliderWrapper}>
              {!isLoading && <VideoSlider headerContent={trendingnHeading} />}
            </div>
          )}
        </div>

        <div>
          {inspiredYet && (
            <Inspired zenMode={zenMode} inspiredYet={inspiredYet} />
          )}
        </div>
        {topPostContent ? (
          <div className={classes.topPostsWrapper}>
            <TopPosts
              content={topPostContent}
              zenMode={zenMode}
              community={true}
            />
          </div>
        ) : (
          ''
        )}

        <div className={classes.configBox}>
          <div className={classes.configInnerBox}>
            {campaignHeading ? (
              <Box my={{ md: 24, sm: 20, xs: 16 }}>
                <BrandsCollab headerContent={campaignHeading} />
              </Box>
            ) : (
              ''
            )}

            {meetNewFriends && (
              <MeetNewFriend
                content={{
                  heading: meetNewFriends?.heading,
                  para: meetNewFriends?.text,
                  btnDetail: {
                    content: meetNewFriends?.button.content,
                    href: meetNewFriends?.button.href
                      ? meetNewFriends?.button.href
                      : '#',
                  },
                }}
                zenMode={zenMode}
              />
            )}
          </div>
        </div>
      </Box>
    </>
  )
}
// This gets called on every request
export async function getServerSideProps() {
  // Fetch data from external API
  const baseURL =
    process.env.NEXT_PUBLIC_BACKEND_URL ||
    'https://strapi-beta.ditchthelabel.org'
  const endPoint =
    process.env.NEXT_PUBLIC_SITE_LANGUAGE === 'en'
      ? `/api/article-schemas?filters[name][$eq]=homepage&populate=deep`
      : `/api/article-schemas?filters[name][$eq]=mx-homepage&populate=deep&locale=es-MX`
  const url = baseURL + endPoint
  let isLoading = true
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_API_KEY}`,
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
  isLoading = false
  const data = await res.json()

  // Pass data to the page via props
  return { props: { data, isLoading } }
}
